import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableSortLabel } from "@mui/material";
import FilterSelectTable from "../../../../Components/Inputs/FilterSelect";
import { ApuestasContext } from "../Context/ApuestasContext";

export default function ApuestasTableHead({ handleSort, order, orderBy }) {
  const {
    catsQuery,
    filter,
    setFilter,
    setFilterCats,
    filterCats,
  } = React.useContext(ApuestasContext);

  const headCells = [
    {
      id: "ticket",
      padding: "normal",
      align: "left",
      label: "Folio",
      sortable: false,
      sx: {
        minWidth: "100px",
      },
    },
    {
      id: "status",
      padding: "normal",
      align: "left",
      label: "Status",
      sortable: false,
      filter: {
        filteringData: "statusApuesta",
        filterName: "status",
        filterBy: "status",
      },
      sx: {
        minWidth: "100px",
      },
    },
    {
      id: "torneo",
      padding: "normal",
      align: "left",
      label: "Torneo",
      sortable: false,
      filter: {
        filteringData: "torneos",
        filterName: "torneo",
        filterBy: "nombre",
      },
      sx: {
        minWidth: "210px",
      },
    },
    {
      id: "charreada",
      padding: "normal",
      align: "left",
      label: "Charreada",
      sortable: false,
      filter: {
        filteringData: "charreadas",
        filterName: "charreada",
        filterBy: "nombre",
      },
      sx: {
        minWidth: "120px",
      },
    },
    {
      id: "suerte",
      padding: "normal",
      align: "left",
      label: "Suerte",
      sortable: false,
      filter: {
        filteringData: "suertes",
        filterName: "suerte",
        filterBy: "suerte",
      },
      sx: {
        minWidth: "110px",
      },
    },
    {
      id: "equipo",
      padding: "normal",
      align: "left",
      label: "Equipo",
      sortable: false,
      filter: {
        filteringData: "equipos",
        filterName: "equipo",
        filterBy: "nombre",
      },
      sx: {
        minWidth: "210px",
      },
    },
    {
      id: "charro",
      padding: "normal",
      align: "left",
      label: "Charro",
      sortable: false,
      filter: {
        filteringData: "charros",
        filterName: "charro",
        filterBy: "nombre",
      },
      sx: {
        minWidth: "210px",
      },
    },
    {
      id: "apuesta",
      padding: "normal",
      align: "left",
      label: "Juego",
      sortable: true,
      sx: {
        minWidth: "100px",
      },
    },
    {
      id: "momio",
      padding: "normal",
      align: "left",
      label: "Momio",
      sortable: true,
      sx: {
        minWidth: "100px",
      },
    },
    {
      id: "fecha",
      padding: "normal",
      align: "left",
      label: "Fecha",
      sortable: true,
      sx: {
        minWidth: "180px",
      },
    },
    {
      id: "pagar",
      padding: "checkbox",
      align: "left",
      label: "Pagar",
      sortable: false,
      sx: null,
    },
    {
      id: "reprint",
      padding: "checkbox",
      align: "left",
      label: "RePrint",
      sortable: false,
      sx: null,
    },
    /* {
      id: "cancelar",
      padding: "checkbox",
      align: "left",
      label: "Cancelar",
      sortable: false,
      sx: null,
    }, */
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={{
              ...headCell.sx,
              "&:hover .button-filter": {
                display: "inline-flex!important",
              },
            }}
            sortDirection={orderBy === headCell.id ? order : false}
            padding={headCell.padding}
            align={headCell.align}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => handleSort(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
            {headCell.filter ? (
              <FilterSelectTable
                query={
                  catsQuery?.data?.getCatalogos[headCell.filter.filteringData]
                }
                loading={catsQuery.loading}
                error={catsQuery.error}
                value={filter}
                setFilter={setFilter}
                setFilterCats={setFilterCats}
                filterCats={filterCats}
                filterName={headCell.filter.filterName}
                filterBy={headCell.filter.filterBy}
              />
            ) : null}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
