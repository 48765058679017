import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Close } from "@mui/icons-material";
import { MainContext } from "../../../../../../Context/MainCtx";
import { GetMovimientosUserReport } from "./queries";
import TableMovimientosUser from "./Table";
import CustomLoader from "../../../../../../Components/CustomLoader";
import ErrorMessage from "../../../../../../Components/ErrorMessage";
import { useQuery } from "@apollo/client";
import FilterChips from "../FilterChips";
import UserExcelExport from "./UserExport";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialFilter = {
  fechaInicio: null,
  fechaFin: null,
  status: "",
  movimiento: "",
};

export default function UserDetail({ idUser, usuarioData, data, nombre}) {
  const [open, setOpen] = React.useState(false);
  const [filter, setFilter] = React.useState(initialFilter);
  const [pageConfig, setPageConfig] = React.useState({ page: 0, limit: 25 });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        size="small"
        sx={{ fontSize: 12, textTransform: "none", py: 0 }}
        onClick={handleClickOpen}
        color="info"
      >
        {usuarioData?.nombre || nombre}
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth
        maxWidth="xl"
        PaperProps={{
          sx: {
            height: "90vh",
          },
        }}
      >
        <DialogTitle component="div">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography
              sx={{ flexGrow: 1 }}
              variant="h6"
            >{`Todos los movimientos de ${usuarioData?.nombre || nombre}`}</Typography>
            <UserExcelExport filter={filter} usuario={idUser} dataComp={data} />
            <Button
              size="small"
              variant="contained"
              disableElevation
              onClick={handleClose}
              sx={{ textTransform: "none" }}
              startIcon={<Close />}
            >
              Cerrar
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{display: 'flex', gap: 2, mb: 1}}>
            <Box>
              <Typography>
                Nombre Completo:{" "}
                <b>{ usuarioData ? `${usuarioData?.nombre} ${usuarioData?.apellidoPaterno} ${usuarioData?.apellidoMaterno}` : nombre}</b>
              </Typography>
              <Typography>
                Email: <b>{usuarioData?.email}</b>
              </Typography>
            </Box>
            <Box>
              <Typography>
                Cuenta paypal: <b>{usuarioData?.paypalAccount}</b>
              </Typography>
              <Typography>
                Balance: <b>{usuarioData?.saldo}</b>
              </Typography>
            </Box>
          </Box>
          <FilterChips filter={filter} setFilter={setFilter} />
          <RenderTable
            usuario={idUser}
            setPageConfig={setPageConfig}
            pageConfig={pageConfig}
            filter={filter}
            setFilter={setFilter}
            dataComp={data}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

const RenderTable = ({
  usuario,
  setPageConfig,
  pageConfig,
  filter,
  setFilter,
  dataComp
}) => {
  const { tokenSesion } = React.useContext(MainContext);
  const { page, limit } = pageConfig;

  const query = useQuery(GetMovimientosUserReport, {
    variables: {
      filter,
      usuario,
      page,
      limit,
    },
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  const { loading, data, error } = query;

  if (loading) return <CustomLoader />;
  if (error) return <ErrorMessage message={error.message} />;

  const datos = data?.getMovimientosUserReport;

  return (
    <TableMovimientosUser
      query={datos}
      setPageConfig={setPageConfig}
      pageConfig={pageConfig}
      filter={filter}
      setFilter={setFilter}
      dataComp={dataComp}
    />
  );
};
