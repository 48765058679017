import { gql } from "@apollo/client";

export const GetCatsMov = gql`
  query getCatalogosMov($filter: filterCatInput) {
    getCatalogosMov(filter: $filter) {
      movimientos {
        movimiento
      }
      status {
        status
      }
      torneos {
        nombre
      }
    }
  }
`;
export const GetAllCatsCats = gql`
  query GetCatalogos($filter: filterCatInput) {
    getCatalogos(filter: $filter) {
      charreadas {
        _id
        nombre
      }
      torneos {
        _id
        nombre
      }
      equipos {
        _id
        nombre
      }
      suertes {
        _id
        suerte
      }
      charros {
        _id
        nombre
      }
    }
  }
`;
export const GetPendientes = gql`
  query Query {
    getMovPendientes
  }
`;
export const GetMovimientosReport = gql`
  query getMovimientosReport(
    $filter: usuerFilterMovs
    $limit: Int
    $page: Int
  ) {
    getMovimientosReport(filter: $filter, limit: $limit, page: $page) {
      efectivoTotal
      balanceMovimientoTotal
      # movimientos {
      #   _id
      #   usuario
      #   usuarioData {
      #     nombre
      #     apellidoPaterno
      #     apellidoMaterno
      #     saldo
      #     email
      #     telefono
      #     paypalAccount
      #   }
      #   torneo
      #   torneoData {
      #     _id
      #     nombre
      #     lugar
      #     lienzoCharro
      #     fechaInicio
      #     fechaFin
      #     status
      #     tipo
      #   }
      #   competencia
      #   competenciaData {
      #     torneo
      #     torneoData {
      #       nombre
      #     }
      #     charreada
      #     charreadaData {
      #       nombre
      #     }
      #     equipo
      #     equipoData {
      #       nombre
      #     }
      #     suerte
      #     suerteData {
      #       suerte
      #     }
      #     charro
      #     charroData {
      #       nombre
      #     }
      #     status
      #     ganador
      #   }
      #   balanceAnterior
      #   nuevoBalance
      #   balanceEnMovimiento
      #   metodoPago
      #   monto
      #   concepto
      #   movimiento
      #   status
      #   comentarios
      #   fecha
      #   orderID
      #   payerID
      #   paymentID
      #   paymentSource
      #   full_description
      #   torneo_name
      #   suerte_name
      #   charro_name
      #   charreada_name
      # }
      docs {
        _id
        usuario
        nombre_usuario
        usuarioData {
          nombre
          apellidoPaterno
          apellidoMaterno
          saldo
          email
          telefono
          paypalAccount
        }
        torneo
        torneoData {
          _id
          nombre
          lugar
          lienzoCharro
          fechaInicio
          fechaFin
          status
          tipo
        }
        competencia
        competenciaData {
          torneo
          torneoData {
            nombre
          }
          charreada
          charreadaData {
            nombre
          }
          equipo
          equipoData {
            nombre
          }
          suerte
          suerteData {
            suerte
          }
          charro
          charroData {
            nombre
          }
          status
          ganador
        }
        balanceAnterior
        nuevoBalance
        balanceEnMovimiento
        metodoPago
        monto
        concepto
        movimiento
        status
        comentarios
        fecha
        orderID
        payerID
        paymentID
        paymentSource
        full_description
        torneo_name
        suerte_name
        charro_name
        charreada_name
      }
      totalDocs
      page
      totalPages
    }
  }
`;
