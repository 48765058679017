import React from "react";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { GetTorneosHome } from "./graphql/queries";
import { useQuery } from "@apollo/client";
import { io } from "socket.io-client";
import CustomLoader from "../../../Components/CustomLoader";
import ErrorMessage from "../../../Components/ErrorMessage";
import { formatoFechaHora } from "../../../Config/reuserFunctions";
import { Link } from "react-router-dom";
import { STATUS_TORNEO } from "../../../Config/constantes";
import torneoOro from "../../../assets/images/torneo_oro_largo.png";
import torneoPlata from "../../../assets/images/torneo_plata_largo.png";
import torneoBronce from "../../../assets/images/torneo_bronce_largo.png";
import imgOro from "../../../assets/images/torneos-oro.png";
import imgPlata from "../../../assets/images/torneos-plata.png";
import imgBronce from "../../../assets/images/torneos-bronce.png";
import tournaments from "../../../assets/images/tournaments.png";
import { MainContext } from "../../../Context/MainCtx";
import LazyImage from "../../../Components/LazyImage";
import CheckSesionStatusC from "../CheckSesionStatus";

export default function Torneos() {
  const { setErrors } = React.useContext(MainContext);
  const { loading, data, error, refetch } = useQuery(GetTorneosHome, {
    variables: { filter: "" },
    fetchPolicy: "network-only",
  });

  React.useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_ENDPOINT);
    socket.on("updatedTorneo", () => refetch());
    return () => socket.disconnect();
  }, [refetch]);

  React.useEffect(() => {
    if(error)setErrors({...error, funcion: "getTorneosHome"})
  }, [error, setErrors])

  if (loading) return <CustomLoader />;
  if (error) return <ErrorMessage message={error?.message} />;

  const torneos = data?.getTorneosHome || [];

  return (
    <Box id="torneos" sx={{ py: 1, minHeight: "calc(95vh - 48px)" }}>
      <CheckSesionStatusC />
      <Container>
        <Box
          sx={{
            height: 50,
            width: { xs: "100%", md: 400 },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LazyImage
            src={tournaments}
            alt="torneo"
          />
        </Box>
        <Box mt={2}>
          {torneos.map((res) => (
            <RenderTiposTorneo key={res.tipo} data={res} />
          ))}
        </Box>
      </Container>
    </Box>
  );
}

const RenderTiposTorneo = ({ data }) => {
  const { tipo, torneos } = data;

  const GetImageBanner = () => {
    if (tipo === "ORO") {
      return <LazyImage id="torneo-oro" src={torneoOro} alt="torneo" />;
    } else if (tipo === "PLATA") {
      return <LazyImage id="torneo-plata" src={torneoPlata} alt="torneo" />;
    } else {
      return <LazyImage id="torneo-bronce" src={torneoBronce} alt="torneo" />;
    }
  };
  const GetImage = () => {
    if (tipo === "ORO") {
      return <LazyImage src={imgOro} alt="torneo" />;
    } else if (tipo === "PLATA") {
      return <LazyImage src={imgPlata} alt="torneo" />;
    } else {
      return <LazyImage src={imgBronce} alt="torneo" />;
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 3 }}>
      <Box
        sx={{
          height: 50,
          width: { xs: "100%", md: 400 },
          display: "flex",
          alignItems: "center",
        }}
      >
        <GetImageBanner />
      </Box>

      <List>
        {torneos.map((res, index) => {
          const pausado = res.status === STATUS_TORNEO.pausado;
          return (
            <Paper key={index} variant="outlined" sx={{ mb: 1 }}>
              <ListItem disablePadding>
                <ListItemButton
                  component={Link}
                  to={`/torneo/${res._id}`}
                >
                  <Box
                    sx={{
                      width: 120,
                      height: 50,
                      display: {xs: "none", md: "flex"},
                      alignItems: "center",
                      justifyContent: "center",
                      pr: { xs: 1, md: 0 },
                    }}
                  >
                    <GetImage />
                  </Box>
                  <ListItemText
                    primary={
                      <Typography
                        component="div"
                        color={pausado ? "text.secondary" : "InfoText"}
                      >
                        {pausado ? (
                          <span style={{ color: "red", marginRight: 8 }}>
                            {STATUS_TORNEO.pausado}
                          </span>
                        ) : null}
                        {res.nombre}
                      </Typography>
                    }
                    secondary={`${res.lienzoCharro}, ${
                      res.lugar
                    } - ${formatoFechaHora(res.fechaInicio)}`}
                  />
                </ListItemButton>
              </ListItem>
            </Paper>
          );
        })}
      </List>
    </Container>
  );
};
