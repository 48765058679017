import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { CompetenciaContext } from "../Context/CompetenciaContext";

export default function ShowCalcs({ sx = null }) {
  const { showCalc, setShowCalc } = React.useContext(CompetenciaContext);

  const handleChange = (e) => {
    const { checked } = e.target;
    setShowCalc(checked);
    localStorage.setItem("ShowCalc", JSON.stringify(checked));
  };

  return (
    <FormControlLabel
      control={
        <Checkbox onChange={handleChange} checked={showCalc ? true : false} />
      }
      label="Mostrar cáculos"
    />
  );
}
