import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import {
  EmailOutlined,
  Facebook,
  FormatListNumbered,
  Grading,
  Help,
  InfoOutlined,
  Instagram,
  LiveHelpOutlined,
  PolicyOutlined,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Divider, IconButton } from "@mui/material";
import moment from "moment-timezone";
import logoHoriz from "../../assets/images/logoHoriz.png";
import LazyImage from "../LazyImage";

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  margin: theme.spacing(2),
  backgroundColor: theme.palette.divider,
  borderRadius: 3,
}));

export default function FooterResponsive() {
  const [open, setOpen] = React.useState(false);
  const year = moment().year();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <div>
      <IconButton color="primary" onClick={toggleDrawer}>
        <Help />
      </IconButton>
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
        slotProps={{
          root: {
            style: { zIndex: 1000 },
          },
        }}
        PaperProps={{
          sx: {
            height: "80vh",
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            zIndex: 0,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Puller />
        </Box>
        <Box
          sx={{
            px: 2,
            pb: 2,
            mb: 2,
            height: "100%",
            overflow: "auto",
          }}
        >
          <List>
            <ListItem disablePadding>
              <ListItemButton
                onClick={toggleDrawer}
                component={Link}
                to="/about"
              >
                <ListItemIcon>
                  <InfoOutlined />
                </ListItemIcon>
                <ListItemText primary="Quiénes somos" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={toggleDrawer}
                component={Link}
                to="/terminos"
              >
                <ListItemIcon>
                  <Grading />
                </ListItemIcon>
                <ListItemText primary="Términos y condiciones" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={toggleDrawer}
                component={Link}
                to="/politicas"
              >
                <ListItemIcon>
                  <PolicyOutlined />
                </ListItemIcon>
                <ListItemText primary="Políticas de privacidad" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={toggleDrawer} component={Link} to="/faq">
                <ListItemIcon>
                  <LiveHelpOutlined />
                </ListItemIcon>
                <ListItemText primary="Preguntas frecuentes" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={toggleDrawer}
                component={Link}
                to="/indicaciones"
              >
                <ListItemIcon>
                  <FormatListNumbered />
                </ListItemIcon>
                <ListItemText primary="Indicaciones para jugar" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={toggleDrawer}
                component={Link}
                to="/contacto"
              >
                <ListItemIcon>
                  <EmailOutlined />
                </ListItemIcon>
                <ListItemText primary="Contáctanos" />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem disablePadding>
              <ListItemButton
                onClick={toggleDrawer}
                component="a"
                target="_blank"
                href="https://www.facebook.com/SuerteCharraMX"
              >
                <ListItemIcon>
                  <Facebook />
                </ListItemIcon>
                <ListItemText primary="Facebook" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={toggleDrawer}
                component="a"
                target="_blank"
                href="https://www.instagram.com/suertecharramx"
              >
                <ListItemIcon>
                  <Instagram />
                </ListItemIcon>
                <ListItemText primary="Instagram" />
              </ListItemButton>
            </ListItem>
          </List>
          <Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
            <LazyImage src={logoHoriz} alt="suerte charra" height={50} />
          </Box>
          <Typography align="center">
            Copyright {year}© suertecharra.com
          </Typography>
        </Box>
        <Toolbar />
      </SwipeableDrawer>
    </div>
  );
}
