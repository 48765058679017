import React from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { Done, Visibility, VisibilityOff } from "@mui/icons-material";
import { MainContext } from "../../../Context/MainCtx";
import { useMutation } from "@apollo/client";
import { UpdateUser } from "./gql/mutations";
import DeleteAccount from "./DeleteAccount";
//import moment from "moment-timezone";
import { Container, Grid, Select } from "@mui/material";
import IDFrontUpload from "./UploadsIds";
import DateInputEditable from "../../../Components/Inputs/DateInputEditable";

const instituciones = [
  "BBVA",
  "Santander",
  "Banorte",
  "Banamex",
  "Scotiabank",
  "Inbursa",
  "HSBC",
  "Banco del Bajío",
  "Banregio",
  "Otro",
];

const tipoNumero = [
  "Número de Tarjeta",
  "Clabe Interbancaria",
]

export default function UserForm({ data, refetch }) {
  const { snackMessage, tokenSesion, setErrors } = React.useContext(
    MainContext
  );
  const [loading, setLoading] = React.useState(false);
  const [view, setView] = React.useState(false);
  const [user, setUser] = React.useState({
    ...data?.getUser,
    password: "",
    repeatPassword: "",
  });
  const [imagenes, setImagenes] = React.useState([]);
  const [updateUser] = useMutation(UpdateUser);

  React.useEffect(() => {
    if (data?.getUser?.imagenesID?.length) {
      setImagenes(data.getUser.imagenesID);
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  /* const handleChangeDate = (name, value) => {
    setUser((st) => ({ ...st, [name]: moment(value).format("L") }));
  }; */

  const handleSave = async (e) => {
    try {
      e.preventDefault();
      if (user.password && user.password !== user.repeatPassword) {
        snackMessage({
          message: "Las contraseñas no coiciden",
          variant: "error",
        });
        return;
      }
      setLoading(true);
      let noAwsImages = [...imagenes].filter((res) => !res.key);
      const { _id, ...input } = user;
      const response = await updateUser({
        variables: {
          idUser: _id,
          input: {
            ...input,
            newImgs: noAwsImages,
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.updateUser.message,
        variant: "success",
      });
      refetch();
      setLoading(false);
      setUser({
        ...user,
        password: "",
        repeatPassword: "",
        deletedImgs: []
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      snackMessage({
        message: error.message ? error.message : "Error Desconocido",
        variant: "error",
      });
      setErrors({ ...error, funcion: "updateUser" });
    }
  };

  return (
    <Container maxWidth="md" sx={{ p: { md: 4, xs: 1 } }}>
      <form onSubmit={handleSave}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Typography variant="h6" noWrap>
            Configuración de mi cuenta
          </Typography>
          <Button
            size="small"
            color="primary"
            type="submit"
            variant="contained"
            disableElevation
            disabled={loading}
            sx={{ textTransform: "none" }}
            startIcon={
              loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <Done />
              )
            }
          >
            Guardar
          </Button>
        </Box>
        <Box mb={2}>
          <Typography variant="subititle2">
            <b>Información básica</b>
          </Typography>
          <Divider />
        </Box>
        <Grid container spacing={1}>
          <Grid item>
            <Typography sx={{ lineHeight: 1 }} variant="body2" color="gray">
              Nombres(s)<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              name="nombre"
              required
              value={user?.nombre}
              disabled
            />
          </Grid>
          <Grid item md={2} sx={12}>
            <Typography sx={{ lineHeight: 1 }} variant="body2" color="gray">
              A. Paterno<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              name="apellidoPaterno"
              required
              value={user?.apellidoPaterno}
              disabled
            />
          </Grid>
          <Grid item md={2} sx={12}>
            <Typography sx={{ lineHeight: 1 }} variant="body2" color="gray">
              A. Materno<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              name="apellidoMaterno"
              required
              value={user?.apellidoMaterno}
              disabled
            />
          </Grid>
          <Grid item md={2.5} sx={12}>
            <Typography sx={{ lineHeight: 1 }} variant="body2" color="gray">
              F. nacimiento<span style={{ color: "red" }}>*</span>
            </Typography>
            <DateInputEditable
              name="fechaNacimiento"
              value={user?.fechaNacimiento}
              disabled
              required
            />
          </Grid>
        </Grid>
        <Box mb={2} mt={2}>
          <Typography variant="subititle2">
            <b>Información de contácto</b>
          </Typography>
          <Divider />
        </Box>
        <Grid container spacing={1}>
          <Grid item md={6} xs={12}>
            <Typography sx={{ lineHeight: 1 }} variant="body2" color="gray">
              Direccion (Como en tu Identificacion)
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              name="direccion"
              required
              value={user?.direccion}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography sx={{ lineHeight: 1 }} variant="body2" color="gray">
              Correo electrónico
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              disabled
              value={user?.email}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography sx={{ lineHeight: 1 }} variant="body2" color="gray">
              Telefono
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              name="telefono"
              required
              value={user?.telefono}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Box mb={2} mt={2}>
          <Typography variant="subititle2">
            <b>Información Bancaria</b>
          </Typography>
          <Divider />
        </Box>
        <Grid container spacing={1}>
          <Grid item md={4} xs={12}>
            <Typography sx={{ lineHeight: 1 }} variant="body2" color="gray">
              Cuenta de Paypal
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              name="paypalAccount"
              value={user?.paypalAccount}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography sx={{ lineHeight: 1 }} variant="body2" color="gray">
              Institución bancaria
              {user.clabeBanco ? <span style={{ color: "red" }}>*</span> : null}
            </Typography>
            <FormControl fullWidth size="small" name="institucionBancaria" required={user.clabeBanco && true}>
              <Select
                name="institucionBancaria"
                value={user?.institucionBancaria}
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>Selecciona una opción</em>
                </MenuItem>
                {instituciones.map((res) => (
                  <MenuItem key={res} value={res}>
                    {res}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>  
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography sx={{ lineHeight: 1 }} variant="body2" color="gray">
              Tipo de Número bancario
            </Typography>
            <FormControl fullWidth size="small" name="tipoClabeBanco">
              <Select
                name="tipoClabeBanco"
                value={user?.tipoClabeBanco}
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>Selecciona una opción</em>
                </MenuItem>
                {tipoNumero.map((res) => (
                  <MenuItem key={res} value={res}>
                    {res}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography sx={{ lineHeight: 1 }} variant="body2" color="gray">
              {user?.tipoClabeBanco || "clabe o número"}
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              name="clabeBanco"
              value={user?.clabeBanco}
              onChange={handleChange}
            />
          </Grid>
          
        </Grid>
        <Box mb={1} mt={2}>
          <Typography variant="subititle2">
            <b>Identificación (frente y reverso)</b>
          </Typography>
          <Divider />
        </Box>
        <IDFrontUpload
          imagenes={imagenes}
          setImagenes={setImagenes}
          user={user}
          setUser={setUser}
        />
        <Box mb={1} mt={2}>
          <Typography variant="subititle2">
            <b>Seguridad</b>
          </Typography>
          <Divider />
        </Box>
        <Box>
          <Typography variant="subtitle2">
            <b>Cambiar contraseña</b>
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            label="Nueva contraseña"
            fullWidth
            margin="dense"
            name="password"
            type={view ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setView(!view)}>
                    {view ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={user.password}
            required={user.password ? true : false}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            size="small"
            label="Confirma tu contraseña"
            fullWidth
            margin="dense"
            name="repeatPassword"
            value={user.repeatPassword}
            required={user.password ? true : false}
            type={view ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setView(!view)}>
                    {view ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={handleChange}
            error={
              user.password &&
              user.repeatPassword &&
              user.password !== user.repeatPassword
                ? true
                : false
            }
          />
        </Box>
        <DeleteAccount idUser={user._id} />
      </form>
    </Container>
  );
}
