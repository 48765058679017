import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { MovimientoContext } from "../Context/MovimientoContext";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import esLocale from "date-fns/locale/es";
import moment from "moment-timezone";
import { TIPO_MOVIMIENTO } from "../../../../../Config/constantes";
import { Button, CircularProgress } from "@mui/material";
import { Search } from "@mui/icons-material";

export default function FiltrosSearch() {
  const { filter, setFilter, query, getMovimientos } = React.useContext(
    MovimientoContext
  );

  const handleChange = (name, value) => {
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterMoment} locale={esLocale}>
        <DemoContainer components={["DateTimePicker", "DateTimePicker"]}>
          <DateTimePicker
            label="Desde"
            value={moment(filter)}
            slotProps={{
              textField: {
                fullWidth: true,
                size: "small",
              },
            }}
            onChange={(val) =>
              handleChange(
                "fechaInicio",
                moment(val).format()
              )
            }
          />

          <DateTimePicker
            label="Hasta"
            value={moment(filter)}
            onChange={(val) =>
              handleChange(
                "fechaFin",
                moment(val).format()
              )
            }
            slotProps={{
              textField: {
                fullWidth: true,
                size: "small",
              },
            }}
          />
        </DemoContainer>
      </LocalizationProvider>
      <FormControl fullWidth margin="normal" size="small">
        <InputLabel id="tipo-movimiento-select">Tipo de movimiento</InputLabel>
        <Select
          labelId="tipo-movimiento-select"
          value={filter.movimiento}
          label="Tipo de movimiento"
          onChange={(e) => handleChange("movimiento", e.target.value, e)}
        >
          <MenuItem value="">
            <em>Selecciona una opción</em>
          </MenuItem>
          <MenuItem value={TIPO_MOVIMIENTO.compra}>Compras</MenuItem>
          <MenuItem value={TIPO_MOVIMIENTO.errorCompra}>
            Errores Compra
          </MenuItem>
          <MenuItem value={TIPO_MOVIMIENTO.retiro}>Retiros</MenuItem>
          <MenuItem value={TIPO_MOVIMIENTO.apuesta}>Juegos</MenuItem>
          <MenuItem value={TIPO_MOVIMIENTO.apuestaGanada}>
            Juegos Ganados
          </MenuItem>
          <MenuItem value={TIPO_MOVIMIENTO.apuestaCancelada}>
            Juegos Cancelados
          </MenuItem>
        </Select>
      </FormControl>
      <Box sx={{ mt: 1, display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          disableElevation
          disabled={query.loading}
          startIcon={
            query.loading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <Search />
            )
          }
          onClick={() => getMovimientos()}
        >
          Buscar
        </Button>
      </Box>
    </Box>
  );
}
