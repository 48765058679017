import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import {
  AlternateEmail,
  LockOpen,
  Login,
  PhoneOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { MainContext } from "../../../Context/MainCtx";
import { Link } from "react-router-dom";
import { SendEmailConfirm } from "./gql/mutations";
import DateInput from "../../../Components/Inputs/DateInput";
import moment from "moment-timezone";
import ReCAPTCHA from "react-google-recaptcha";
import { VerifyReCaptcha } from "../SignIn/gql/mutations";

const initialData = {
  nombre: "",
  apellidoPaterno: "",
  apellidoMaterno: "",
  direccion: "",
  fechaNacimiento: "",
  email: "",
  telefono: "",
  password: "",
  repeatPassword: "",
  terminos: false,
};

export default function SignUpForm() {
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(initialData);
  const [verified, setVerified] = React.useState(false);
  const navigate = useNavigate();
  const [createConfirmation] = useMutation(SendEmailConfirm);
  const [verifyReCaptcha] = useMutation(VerifyReCaptcha);
  const { snackMessage, setErrors } = React.useContext(MainContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((st) => ({ ...st, [name]: value }));
  };

  // Calcula la fecha mínima permitida (hace 18 años desde hoy)
  const today = moment();
  const eighteenYearsAgo = today.clone().subtract(18, 'years');

  const handleChangeDate = (name, value) => {
    setData((st) => ({ ...st, [name]: moment(value).format("L") }));
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setData((st) => ({ ...st, [name]: checked }));
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (today.diff(data.fechaNacimiento, 'years') < 18) {
        snackMessage({
          message: "Debes ser mayor de 18 años.",
          variant: "info",
        });
        return
      }
      if (data.password !== data.repeatPassword) {
        snackMessage({
          message: "Las contraseñas no coiciden",
          variant: "error",
        });
        return;
      }
      setLoading(true);
      const { terminos, ...input } = data;
      const response = await createConfirmation({
        variables: {
          input,
        },
      });
      setLoading(false);
      snackMessage({
        message: response.data.createConfirmation.message,
        variant: "success",
      });
      setData(initialData);
      navigate("/signin");
    } catch (err) {
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
      setErrors({...err, funcion: "createConfirmation"})
    }
  };

  //reCAPTCHA
  const handleRecaptchaChange = async (token) => {
    // enviar token a backend para verificarlo
    try {
      await verifyReCaptcha({
        variables: { token },
      });
      setVerified(true);
    } catch (error) {
      console.log(error);
      snackMessage({
        message: error.message,
        variant: "error",
      });
    }
  };

  return (
    <Box sx={{ width: 370 }}>
      <Paper sx={{ p: 2 }}>
        <Box sx={{ mb: 1 }}>
          <Typography variant="h5" align="center">
            <b>Regístrate</b>
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            size="small"
            label="Nombre(s)"
            variant="outlined"
            margin="dense"
            required
            name="nombre"
            value={data.nombre}
            onChange={handleChange}
          />
          <Box sx={{ display: "flex", gap: 0.5 }}>
            <TextField
              fullWidth
              size="small"
              label="Apellido Paterno"
              variant="outlined"
              margin="dense"
              required
              name="apellidoPaterno"
              value={data.apellidoPaterno}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              size="small"
              label="Apellido Materno"
              variant="outlined"
              margin="dense"
              required
              name="apellidoMaterno"
              value={data.apellidoMaterno}
              onChange={handleChange}
            />
          </Box>

          <DateInput
            name="fechaNacimiento"
            value={data.fechaNacimiento}
            onChange={handleChangeDate}
            required
            margin="dense"
            label="Fecha de nacimiento"
            fullwidth
            maxDate={eighteenYearsAgo}
          />
          <TextField
            fullWidth
            size="small"
            label="Domicilio (como en identificación)"
            variant="outlined"
            margin="dense"
            required
            name="direccion"
            value={data.direccion}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            size="small"
            label="Correo electrónico"
            variant="outlined"
            margin="dense"
            required
            name="email"
            type="email"
            value={data.email}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AlternateEmail />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            size="small"
            label="Telefono"
            variant="outlined"
            margin="dense"
            required
            name="telefono"
            type="tel"
            value={data.telefono}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneOutlined />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            fullWidth
            size="small"
            placeholder="Contraseña"
            variant="outlined"
            margin="dense"
            required
            type={show ? "text" : "password"}
            name="password"
            value={data.password}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOpen />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShow(!show)}>
                    {show ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            size="small"
            placeholder="Confirmar contraseña"
            variant="outlined"
            margin="dense"
            required
            type={show ? "text" : "password"}
            name="repeatPassword"
            value={data.repeatPassword}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOpen />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShow(!show)}>
                    {show ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormControlLabel
            required
            control={
              <Checkbox
                name="terminos"
                value={data.terminos}
                onChange={handleCheck}
              />
            }
            label={
              <Typography component="span" variant="caption">
                Acepto <Link to="/terminos">términos y condiciones</Link>, asi
                como las <Link to="/politicas">políticas de privacidad</Link>
              </Typography>
            }
          />
          <Box sx={{ display: "flex", justifyContent: "center", my: 1.5 }}>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_WEBSITE_KEY}
              onChange={handleRecaptchaChange}
              onExpired={() => setVerified(false)}
            />
          </Box>
          <Button
            sx={{ mt: 1 }}
            fullWidth
            disableElevation
            variant="contained"
            type="submit"
            disabled={loading || !verified}
            startIcon={
              loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <Login />
              )
            }
          >
            Crear cuenta
          </Button>
        </form>
      </Paper>
    </Box>
  );
}
