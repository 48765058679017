import { gql } from "@apollo/client";

export const GetResultados = gql`
  query GetResultados($filter: inputFilter, $limit: Int, $page: Int) {
    getResultados(filter: $filter, limit: $limit, page: $page) {
      docs {
        _id
        torneo
        nombreTorneo
        torneoData {
          _id
          nombre
        }
        equipo
        nombreEquipo
        equipoData {
          _id
          nombre
        }
        suerte
        nombreSuerte
        suerteData {
          _id
          suerte
        }
        charro
        nombreCharro
        charroData {
          _id
          nombre
        }
        usuario
        nombreUsuario
        usuarioData {
          _id
          nombre
        }
        calificacion
        real
        fecha
      }
      page
      totalDocs
      totalPages
    }
  }
`;

export const GetCatsResult = gql`
  query GetCatalogos($filter: filterCatInput) {
    getCatalogos(filter: $filter) {
      torneos {
        _id
        nombre
        lugar
        lienzoCharro
        fechaInicio
        fechaFin
        status
        abrev
        tipo
        createdAt
      }
      equipos {
        _id
        nombre
        estado {
          _id
          estado
          pais
        }
        createdAt
      }
      suertes {
        _id
        suerte
      }
      charros {
        _id
        nombre
        equipo
        equipoData {
          _id
          nombre
          createdAt
        }
        foto
        keyFoto
      }
    }
  }
`;
