import * as React from "react";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { CustomButton } from "../CustomMuiComponents";
import { Close, CloudUpload, DeleteOutline } from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import UploadToDB from "./Upload";
/* import TableXlsx from "./TableXlsx";
import * as XLSX from "xlsx"; */
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";

export default function UploadXlsx({ model, handleCloseMenu, refetchModel, onlyUpload }) {
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState(null);
  //const [fileData, setFileData] = React.useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleRemoveFile();
    handleCloseMenu();
  };

  const onDrop = React.useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    setFile(file);

    /* reader.onload = () => {
      const workbook = XLSX.read(reader.result, { type: "binary" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      setFileData(data);
    }; */

    reader.readAsBinaryString(file);
  }, []);

  const handleRemoveFile = () => {
    setFile(null);
    //setFileData([]);
  };

  function megabytes(bytes) {
    return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <React.Fragment>
      <ListItem onClick={handleClickOpen} sx={{ p: 0 }}>
        <ListItemIcon>
          <CloudUpload />
        </ListItemIcon>
        <ListItemText primary="Subir archivo" />
      </ListItem>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        //maxWidth={!fileData.length ? "md" : "lg"}
      >
        <DialogTitle component="div">
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Importar XLSX
            </Typography>
            <CustomButton
              variant="outlined"
              size="small"
              color="info"
              disableElevation
              onClick={handleRemoveFile}
              startIcon={<DeleteOutline />}
            >
              Remover
            </CustomButton>
            <UploadToDB
              handleClose={handleClose}
              file={file}
              model={model}
              refetchModel={refetchModel}
              onlyUpload={onlyUpload}
            />
            <CustomButton
              variant="contained"
              size="small"
              color="inherit"
              disableElevation
              onClick={handleClose}
              startIcon={<Close />}
            >
              Cerrar
            </CustomButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {!file ? (
            <Box
              {...getRootProps()}
              sx={{
                border: "2px dashed #ccc",
                borderRadius: "5px",
                padding: "20px",
                textAlign: "center",
                cursor: "pointer",
                marginBottom: "20px",
              }}
            >
              <input {...getInputProps()} />
              <p>
                Arrastra y suelta archivos XLSX aquí, o haz clic para
                seleccionar archivos
              </p>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: 100
              }}
            >
              <Typography>{file.name}</Typography>
              <Typography>Size: {megabytes(file.size)}</Typography>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}