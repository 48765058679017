import { gql } from "@apollo/client";

export const UpdateUserAdmin = gql`
  mutation updateUserDashboard($idUser: ID!, $input: updateUsuarioDashInput) {
    updateUserDashboard(idUser: $idUser, input: $input) {
      message
    }
  }
`;
export const RecoveryUser = gql`
  mutation recoveryUser($idUser: ID!) {
    recoveryUser(idUser: $idUser) {
      message
    }
  }
`;
export const DeleteUserOld = gql`
  mutation deleteUserOld($idUser: ID!) {
    deleteUserOld(idUser: $idUser) {
      message
    }
  }
`;
export const DeleteUserDash = gql`
  mutation deleteUser($idUser: ID!) {
    deleteUser(idUser: $idUser) {
      message
    }
  }
`;
export const UpdateStatusUser = gql`
  mutation updateStatusUser($idUser: ID!, $activo: Boolean) {
    updateStatusUser(idUser: $idUser, activo: $activo) {
      message
    }
  }
`;
export const CreateMasiveEmail = gql`
  mutation createEmail($input: emailInput) {
    createEmail(input: $input) {
      message
    }
  }
`;
export const DeleteEmailsTemp = gql`
  mutation deleteEmails {
    deleteEmails {
      message
    }
  }
`;