import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CircularProgress from "@mui/material/CircularProgress";
import { Delete } from "@mui/icons-material";
import { useMutation } from "@apollo/client";
import { MainContext } from "../../../../../../Context/MainCtx";
import { DeleteManyComp } from "../../graphql/mutations";
import { CompetenciaContext } from "../../Context/CompetenciaContext";

export default function EliminarCompetencias({ handleCloseMenu }) {
  const [loading, setLoading] = React.useState(false);
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const {
    compQuery,
    itemsSelected,
    setItemsSelected,
    setIndeterminated,
  } = React.useContext(CompetenciaContext);
  const [deleteManyComp] = useMutation(DeleteManyComp);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteComps = async () => {
    try {
      if (!itemsSelected.length) return;
      const compToDelete = itemsSelected.map((res) => res._id);
      setLoading(true);
      const res = await deleteManyComp({
        variables: {
          idsComp: compToDelete,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: res.data.deleteManyComp.message,
        variant: "success",
      });
      setLoading(false);
      compQuery.refetch();
      setItemsSelected([]);
      setIndeterminated(false);
      handleClose();
      handleCloseMenu();
    } catch (err) {
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <>
      <ListItem disableGutters disablePadding>
        <ListItemButton onClick={handleClick} disabled={!itemsSelected.length}>
          <ListItemIcon>
            <Delete fontSize="large" color="error" />
          </ListItemIcon>
          <ListItemText primary="Eliminar" />
        </ListItemButton>
      </ListItem>
      <Dialog open={open} fullWidth maxWidth="xs">
        <DialogTitle>
          Se van a eliminar {itemsSelected.length} competencias
        </DialogTitle>
        <DialogActions>
          <Button
            sx={{ textTransform: "none" }}
            color="inherit"
            size="small"
            onClick={handleClose}
            variant="contained"
            fullWidth
            disableElevation
          >
            Cancelar
          </Button>
          <Button
            sx={{ textTransform: "none" }}
            color="error"
            size="small"
            onClick={handleDeleteComps}
            variant="contained"
            fullWidth
            disableElevation
            startIcon={
              loading ? <CircularProgress color="inherit" size={20} /> : null
            }
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
