export const MOMIO_MIN = 0.05
export const MOMIO_MAX = 9.99

export const ROLES = {
  USER: "USER",
  ADMIN: process.env.REACT_APP_ROL_ADMON,
  MANAGER: process.env.REACT_APP_ROL_MANAGER,
  SELLER: process.env.REACT_APP_ROL_SELLER,
  FILIAL: "FILIAL",
};
export const STATUS_TORNEO = {
  pausado: "PAUSADO",
  pendiente: "PENDIENTE",
  activo: "ACTIVO",
  cerrado: "CERRADO",
}
export const STATUS_COMP = {
  pendiente: "PENDIENTE",
  activo: "ACTIVO",
  cerrado: "CERRADO",
  ganador: "GANADOR",
}
export const STATUS_APUESTA = {
  enCurso: "EN CURSO",
  ganada: "GANADA",
  pendiente: "PENDIENTE",
  perdida: "PERDIDA",
  cancelada: "CANCELADA",
}


/* MOVIMIENTOS */
export const STATUS_MOVIMIENTO = {
  realizado: "REALIZADO",
  fallido: "FALLIDO",
  pendiente: "PENDIENTE",
  cancelado: "CANCELADO",
  rechazado: "RECHAZADO",
  en_proceso: "EN PROCESO",
}
export const TIPO_MOVIMIENTO = {
  retiro: "RETIRO",
  compra: "COMPRA",
  errorCompra: "ERROR COMPRA",
  apuesta: "JUEGO",
  apuestaGanada: "JUEGO GANADO",
  apuestaCancelada: "JUEGO CANCELADO",
  pagoApuesta: "PAGO JUEGO"
}
export const METODOS_PAGO = {
  efectivo: "EFECTIVO",
  tarjeta: "TARJETA",
  transferencia: "TRANSFERENCIA",
  paypal: "PAYPAL"
}
export const TORO = 1