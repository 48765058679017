import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import DialogTitle from "@mui/material/DialogTitle";
import { ArrowBack, Add, Edit } from "@mui/icons-material";
import { Done } from "@mui/icons-material";
import { MainContext } from "../../../../../Context/MainCtx";
import { CompetenciaContext } from "../Context/CompetenciaContext";
import { CreateCompetencia, UpdateCompetencia } from "../graphql/mutations";
import { useMutation } from "@apollo/client";
import { CustomButton } from "../../../../../Components/CustomMuiComponents";
import AutocompleteInput from "../../../../../Components/Inputs/AutocompleteInput";
import CalculatedFields from "./CalculatedFields";
import { MOMIO_MAX, MOMIO_MIN, STATUS_COMP } from "../../../../../Config/constantes";
import DateTimeInput from "../../../../../Components/DateTimeInput";

const initialState = {
  torneo: null,
  torneoData: null,
  charreada: null,
  charreadaData: null,
  equipo: null,
  equipoData: null,
  suerte: null,
  suerteData: null,
  charro: null,
  charroData: null,
  multiplicador: 1,
  momioMin: MOMIO_MIN,
  momioMax: MOMIO_MAX,
  status: STATUS_COMP.activo
};

export default function CrearCompetencia({ data = "", edit = false }) {
  const { compQuery, catsQuery, setFilterCats, filterCats } = React.useContext(
    CompetenciaContext
  );
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const [loading, setLoading] = React.useState(false);
  const [createCompetencia] = useMutation(CreateCompetencia);
  const [updateCompetencia] = useMutation(UpdateCompetencia);
  const [open, setOpen] = React.useState(false);
  const [competencia, setCompetencia] = React.useState(initialState);

  const handleOpen = (event) => {
    event.stopPropagation();
    setOpen(true);
    if (edit) setCompetencia(data);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setOpen(false);
    setCompetencia(initialState);
  };

  const onchangeInput = (value, name) => {
    setFilterCats({ ...filterCats, [name]: value });
  };

  const handleChangeDate = (name, value) => {
    setCompetencia((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    try {
      e.stopPropagation();
      e.preventDefault();
      if (loading) return;
      setLoading(true);
      const {
        torneoData,
        charreadaData,
        equipoData,
        charroData,
        suerteData,
        ...input
      } = competencia;
      const response = await createCompetencia({
        variables: {
          input,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.createCompetencia.message,
        variant: "success",
      });
      compQuery.refetch();
      setLoading(false);
      handleClose(e);
    } catch (err) {
      console.log(err.networkError?.result);
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };
  const handleUpdate = async (e) => {
    try {
      e.stopPropagation();
      e.preventDefault();
      if (loading) return;
      setLoading(true);
      const {
        _id,
        createdAt,
        status,
        ganador,
        torneoData,
        charreadaData,
        equipoData,
        charroData,
        suerteData,
        noSePresento,
        torneo_name,
        ...input
      } = competencia;
      const response = await updateCompetencia({
        variables: {
          idCompetencia: competencia._id,
          input,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.updateCompetencia.message,
        variant: "success",
      });
      compQuery.refetch();
      setLoading(false);
      handleClose(e);
    } catch (err) {
      console.log(err);
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      {edit ? (
        <IconButton onClick={handleOpen} size="small">
          <Edit />
        </IconButton>
      ) : (
        <CustomButton
          color="primary"
          variant="contained"
          startIcon={<Add />}
          onClick={handleOpen}
          size="small"
          disableElevation
        >
          Nuevo
        </CustomButton>
      )}
      <Drawer
        anchor={"right"}
        open={open}
        onClose={handleClose}
        ModalProps={{
          componentsProps: {
            backdrop: {
              sx: { background: "rgb(0,0,0, 0.1)" },
            },
          },
        }}
        sx={{
          zIndex: 1200,
        }}
        PaperProps={{
          elevation: 1,
          sx: { width: { xs: "100vw", md: "35vw" }, overflowY: "auto" },
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <Toolbar variant="dense" />
        <DialogTitle component="div">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <IconButton onClick={handleClose}>
                <ArrowBack />
              </IconButton>
              <Typography variant="h6" color="gray">
                Nueva competencia
              </Typography>
            </Stack>
            <Button
              size="small"
              variant="contained"
              disableElevation
              sx={{ textTransform: "none" }}
              startIcon={
                loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <Done />
                )
              }
              type="submit"
              form="form-competencia"
            >
              Guardar
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ height: "80vh" }}>
            <form
              id="form-competencia"
              onSubmit={edit ? handleUpdate : handleSave}
            >
              <Box sx={{ mb: 0.5 }}>
                <Typography variant="body2" color="gray">
                  <span style={{ color: "red" }}>*</span>Torneo
                </Typography>
                <AutocompleteInput
                  renderData={catsQuery.data?.getCatalogos.torneos}
                  value={competencia.torneoData}
                  inputValue={filterCats.torneo}
                  loading={catsQuery.loading}
                  error={catsQuery.error}
                  required
                  onChange={(_, option) => {
                    setCompetencia({
                      ...competencia,
                      torneo: option?._id || null,
                      torneoData: option
                        ? {
                            nombre: option.nombre,
                            _id: option._id,
                          }
                        : null,
                    });
                  }}
                  onInputChange={(_, value) => onchangeInput(value, "torneo")}
                  isOptionEqualToValue={(option, value) =>
                    option.nombre === value.nombre
                  }
                  getOptionLabel={(option) => option?.nombre || ""}
                />
              </Box>
              <Box sx={{ mb: 0.5 }}>
                <Typography variant="body2" color="gray">
                  <span style={{ color: "red" }}>*</span>Charreada
                </Typography>
                <AutocompleteInput
                  renderData={catsQuery.data?.getCatalogos.charreadas}
                  value={competencia.charreadaData}
                  inputValue={filterCats.charreada}
                  loading={catsQuery.loading}
                  required
                  error={catsQuery.error}
                  onChange={(_, option) => {
                    setCompetencia((state) => ({
                      ...state,
                      charreada: option?._id || "",
                      charreadaData: {
                        nombre: option?.nombre || "",
                      },
                    }));
                  }}
                  onInputChange={(_, value) =>
                    onchangeInput(value, "charreada")
                  }
                  isOptionEqualToValue={({ nombre }, value) =>
                    nombre === value.nombre
                  }
                  getOptionLabel={(option) => option?.nombre || ""}
                />
              </Box>
              <Box sx={{ mb: 0.5 }}>
                <Typography variant="body2" color="gray">
                  Equipo
                </Typography>
                <AutocompleteInput
                  renderData={catsQuery.data?.getCatalogos.equipos}
                  value={competencia.equipoData}
                  inputValue={filterCats.equipo}
                  loading={catsQuery.loading}
                  error={catsQuery.error}
                  onChange={(_, option) => {
                    setCompetencia((state) => ({
                      ...state,
                      equipo: option?._id || "",
                      equipoData: {
                        nombre: option?.nombre || "",
                      },
                    }));
                  }}
                  onInputChange={(_, value) => onchangeInput(value, "equipo")}
                  isOptionEqualToValue={({ nombre }, value) =>
                    nombre === value.nombre
                  }
                  getOptionLabel={(option) => option?.nombre || ""}
                />
              </Box>
              <Box sx={{ mb: 0.5 }}>
                <Typography variant="body2" color="gray">
                  <span style={{ color: "red" }}>*</span>Suerte
                </Typography>
                <AutocompleteInput
                  renderData={catsQuery.data?.getCatalogos.suertes}
                  value={competencia.suerteData}
                  inputValue={filterCats.suerte}
                  loading={catsQuery.loading}
                  required
                  error={catsQuery.error}
                  onChange={(_, option) => {
                    setCompetencia((state) => ({
                      ...state,
                      suerte: option?._id || "",
                      suerteData: {
                        suerte: option?.suerte || "",
                      },
                    }));
                  }}
                  onInputChange={(_, value) => onchangeInput(value, "suerte")}
                  isOptionEqualToValue={({ suerte }, value) =>
                    suerte === value.suerte
                  }
                  getOptionLabel={(option) => option?.suerte || ""}
                />
              </Box>
              <Box sx={{ mb: 0.5 }}>
                <Typography variant="body2" color="gray">
                  Charro
                </Typography>
                <AutocompleteInput
                  renderData={catsQuery.data?.getCatalogos.charros}
                  value={competencia.charroData}
                  inputValue={filterCats.charro}
                  loading={catsQuery.loading}
                  error={catsQuery.error}
                  onChange={(_, option) => {
                    setCompetencia((state) => ({
                      ...state,
                      charro: option?._id || "",
                      charroData: {
                        nombre: option?.nombre || "",
                      },
                    }));
                  }}
                  onInputChange={(_, value) => onchangeInput(value, "charro")}
                  isOptionEqualToValue={({ nombre }, value) =>
                    nombre === value.nombre
                  }
                  getOptionLabel={(option) => option?.nombre || ""}
                />
              </Box>
              <Box sx={{ mb: 0.5 }}>
                <Typography variant="body2" color="gray">
                <span style={{ color: "red" }}>*</span>Status
                </Typography>
                <AutocompleteInput
                  renderData={catsQuery.data?.getCatalogos.status}
                  value={competencia.status}
                  inputValue={competencia.status}
                  loading={catsQuery.loading}
                  error={catsQuery.error}
                  onChange={(_, option) => {
                    setCompetencia((state) => ({
                      ...state,
                      status: option?.status || "",
                    }));
                  }}
                  required
                  isOptionEqualToValue={({ status }, value) =>
                    status === value
                  }
                  getOptionLabel={(option) => option?.status || ""}
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  Fecha charreada
                </Typography>
                <DateTimeInput
                  name="fechaCharreada"
                  onChange={handleChangeDate}
                  value={competencia.fechaCharreada}
                />
              </Box>
              <CalculatedFields
                competencia={competencia}
                setCompetencia={setCompetencia}
              />
            </form>
          </Box>
        </DialogContent>
      </Drawer>
    </div>
  );
}
