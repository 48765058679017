import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableSortLabel } from "@mui/material";
import FilterSelectTable from "../../../../../../Components/Inputs/FilterSelect";
import { MovimientoContext } from "../../Context/MovimientoContext";
import FilterDateRange from "../../../../../../Components/Inputs/FilterDateRange";

const headCells = [
  {
    id: "usuario",
    padding: "normal",
    align: "left",
    label: "Usuario",
    sortable: false,
    sx: {
      minWidth: "200px",
    },
  },
  {
    id: "concepto",
    padding: "normal",
    align: "left",
    label: "Concepto",
    sortable: false,
    sx: {
      minWidth: "200px",
    },
  },
  {
    id: "monto",
    padding: "checkbox",
    align: "left",
    label: "Monto(efectivo)",
    sortable: false,
    sx: null,
  },
  {
    id: "metodoPago",
    padding: "normal",
    align: "left",
    label: "Metodo Pago",
    sortable: false,
    sx: {minWidth: 120},
  },
  {
    id: "status",
    padding: "checkbox",
    align: "left",
    label: "Status",
    sortable: false,
    sx: null,
    filter: {
      filteringData: "status",
      filterName: "status",
      filterBy: "status",
    },
  },
  {
    id: "fecha",
    padding: "normal",
    align: "left",
    label: "Fecha",
    sortable: true,
    sx: {
      minWidth: "200px",
    },
  },

  {
    id: "balanceEnMovimiento",
    padding: "checkbox",
    align: "left",
    label: "Cantidad",
    sortable: false,
    sx: null,
  },
  {
    id: "balanceAnterior",
    padding: "checkbox",
    align: "left",
    label: "Antes",
    sortable: false,
    sx: null,
  },
  {
    id: "nuevoBalance",
    padding: "checkbox",
    align: "left",
    label: "Despues",
    sortable: false,
    sx: null
  },
  {
    id: "comentarios",
    padding: "normal",
    align: "left",
    label: "Comentarios",
    sortable: false,
    sx: {
      minWidth: "200px",
    },
  },
  {
    id: "orderID",
    padding: "normal",
    align: "left",
    label: "OrderID",
    sortable: false,
    sx: {
      minWidth: "100px",
    },
  },
  {
    id: "payerID",
    padding: "normal",
    align: "left",
    label: "PayerID",
    sortable: false,
    sx: {
      minWidth: "100px",
    },
  },
  {
    id: "paymentID",
    padding: "normal",
    align: "left",
    label: "PaymentID",
    sortable: false,
    sx: {
      minWidth: "100px",
    },
  },
  {
    id: "paymentSource",
    padding: "normal",
    align: "left",
    label: "Source",
    sortable: false,
    sx: {
      minWidth: "100px",
    },
  },
];

export default function MovimientosTableHead({ handleSort, order, orderBy }) {
  const {
    catsQuery,
    filter,
    setFilter,
    setFilterCats,
    filterCats,
  } = React.useContext(MovimientoContext);
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={{
              ...headCell.sx,
              "&:hover .button-filter": {
                display: "inline-flex!important",
              },
            }}
            sortDirection={orderBy === headCell.id ? order : false}
            padding={headCell.padding}
            align={headCell.align}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => handleSort(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
            {headCell.filter ? (
              <FilterSelectTable
                query={
                  catsQuery?.data?.getCatalogosMov[
                    headCell.filter.filteringData
                  ]
                }
                loading={catsQuery.loading}
                error={catsQuery.error}
                value={filter}
                setFilter={setFilter}
                filterCats={filterCats}
                setFilterCats={setFilterCats}
                filterName={headCell.filter.filterName}
                filterBy={headCell.filter.filterBy}
              />
            ) : null}
            {headCell.filterDate ? (
              <FilterDateRange
                filter={filter}
                setFilter={setFilter}
                filterName={headCell.filterName}
              />
            ) : null}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
