import { gql } from "@apollo/client";

export const GetCuotas = gql`
  query getCuotas($filter: String, $limit: Int, $page: Int) {
    getCuotas(filter: $filter, limit: $limit, page: $page) {
      docs {
        _id
        suerte
        suerteData {
          _id
          suerte
        }
        cuota
      }
      totalDocs
      page
      totalPages
    }
  }
`;
