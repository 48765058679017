import React from "react";
import { Box, Container } from "@mui/material";
import MovimeintoCtxProvider, {
  MovimientoContext,
} from "./Context/MovimientoContext";
import TableMovimientos from "./Table";
import CustomLoader from "../../../Components/CustomLoader";
import ErrorMessage from "../../../Components/ErrorMessage";
import Export from "./Actions/Export";
import CrearSolicitud from "./Actions/Create";
import FilterDates from "./Actions/FilterDates";
import { MainContext } from "../../../Context/MainCtx";
import { Navigate } from "react-router-dom";
import CheckSesionStatus from "../CheckSesionStatus";

export default function Movimientos() {
  const { tokenDecoded } = React.useContext(MainContext);

  if (!tokenDecoded) return <Navigate to="/" />;

  return (
    <Container>
      <CheckSesionStatus />
      <MovimeintoCtxProvider>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
            my: 1,
          }}
        >
          <FilterDates />
          <Box
            sx={{
              display: "flex",
              gap: 1,
              mb: { xs: 1, md: 0 },
            }}
          >
            <CrearSolicitud />
            <Export />
          </Box>
        </Box>
        <RenderTable />
      </MovimeintoCtxProvider>
    </Container>
  );
}

const RenderTable = () => {
  const { movQuery } = React.useContext(MovimientoContext);
  const { setErrors } = React.useContext(MainContext);
  const { loading, error } = movQuery;

  React.useEffect(() => {
    if(error)setErrors({...error, funcion: "getMovimientosUser"})
  }, [error, setErrors])

  if (loading) return <CustomLoader />;
  if (error) return <ErrorMessage message={error.message} />;

  return <TableMovimientos />;
};
