import { gql } from "@apollo/client";

export const GetMovimientosSeller = gql`
  query getMovimientosSeller($filter: usuerFilterMovs, $limit: Int, $page: Int) {
    getMovimientosSeller(filter: $filter, limit: $limit, page: $page) {
      docs {
        _id
        usuario
        torneo
        torneoData {
          nombre
        }
        competencia
        competenciaData {
          torneo
          charreada
          charreadaData {
            nombre
          }
          equipo
          equipoData {
            nombre
          }
          suerte
          suerteData {
            suerte
          }
          charro
          charroData {
            nombre
          }
          status
          ganador
        }
        balanceEnMovimiento
        movimiento
        status
        fecha
        metodoPago
      }
      totalDocs
      page
      totalPages
    }
  }
`;
export const GetCatsApuesta = gql`
  query GetCatalogos($filter: filterCatInput) {
    getCatalogos(filter: $filter) {
      torneos {
        _id
        nombre
      }
      charreadas {
        _id
        nombre
      }
      equipos {
        _id
        nombre
      }
      suertes {
        _id
        suerte
      }
      charros {
        _id
        nombre
      }
      statusApuesta {
        status
      }
    }
  }
`;
