import React from "react";
import Box from "@mui/material/Box";
import MovimeintoCtxProvider, {
  MovimientoContext,
} from "./Context/MovimientoContext";
import FiltrosSearch from "./Actions/FiltroSearch";
import { TIPO_MOVIMIENTO } from "../../../../Config/constantes";
import ComprasMovsTable from "./Tables/Compras";
import ApuestasMovsTable from "./Tables/Apuestas";
export default function MovimientosAdmin() {
  return (
    <MovimeintoCtxProvider>
      <RenderFilter />
    </MovimeintoCtxProvider>
  );
}

const RenderFilter = () => {
  const { loaded } = React.useContext(MovimientoContext);

  if (loaded) return <RenderResults />;

  return (
    <Box
      sx={{
        height: "40vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <FiltrosSearch />
    </Box>
  );
};

const RenderResults = () => {
  const { filter } = React.useContext(MovimientoContext);

  switch (filter.movimiento) {
    case TIPO_MOVIMIENTO.compra:
      return <ComprasMovsTable />;
    case TIPO_MOVIMIENTO.errorCompra:
      return <ComprasMovsTable />;
    case TIPO_MOVIMIENTO.retiro:
      return <ComprasMovsTable />;
    case TIPO_MOVIMIENTO.apuesta:
      return <ApuestasMovsTable />
    case TIPO_MOVIMIENTO.apuestaGanada:
      return <ApuestasMovsTable />
    case TIPO_MOVIMIENTO.apuestaCancelada:
      return <ApuestasMovsTable />
    default:
      return null;
  }
};
