import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {
  Done,
  EmojiEvents,
  EventBusy,
  InfoOutlined,
  Pending,
  ThumbDown,
} from "@mui/icons-material";
import { STATUS_COMP, STATUS_TORNEO } from "../../../../../Config/constantes";
import { formatoMexico, isMobile } from "../../../../../Config/reuserFunctions";
import { MainContext } from "../../../../../Context/MainCtx";
import { CreateApuesta } from "../../graphql/mutations";
import { useMutation, useQuery } from "@apollo/client";
import { Chip, CircularProgress, Divider, Grid } from "@mui/material";
import DialogSesion from "./DialogSesion";
import { FaCoins } from "react-icons/fa";
import { GetCaculatedMomio } from "../../graphql/queries";
import { io } from "socket.io-client";

export default function CrearApuesta({ data, torneo, charreada, terminado }) {
  const { ganador, cerrado, pendiente } = STATUS_COMP;
  const { pausado } = STATUS_TORNEO;

  const CERRADO = torneo.status === pausado || data.status === cerrado;
  const TERMINADO = CERRADO && !terminado;
  const PERDEDOR = CERRADO && terminado;
  const PAUSADO = data.status === pendiente;
  const GANADOR = data.status === ganador;

  if (GANADOR) {
    return (
      <Box sx={{ display: "flex", gap: 1 }}>
        <Chip
          color="success"
          icon={<EmojiEvents />}
          size="small"
          label="Ganador"
          sx={{ fontSize: { xs: 11, md: 13 } }}
        />
      </Box>
    );
  }
  if (data.noSePresento) {
    return (
      <Box sx={{ display: "flex", gap: 1 }}>
        <Chip
          icon={<EventBusy />}
          size="small"
          label={"No se presentó"}
          sx={{ fontSize: { xs: 11, md: 13 } }}
        />
      </Box>
    );
  }
  if (TERMINADO) {
    return (
      <Box sx={{ display: "flex", gap: 1 }}>
        <Chip
          icon={<Pending />}
          size="small"
          label={isMobile ? "En espera" : "En espera de resultados"}
          sx={{ fontSize: { xs: 11, md: 13 } }}
        />
      </Box>
    );
  }
  if (PERDEDOR) {
    return (
      <Box sx={{ display: "flex", gap: 1 }}>
        <Chip
          color="error"
          icon={<ThumbDown />}
          size="small"
          label={"Perdedor"}
          sx={{ fontSize: { xs: 11, md: 13 } }}
        />
      </Box>
    );
  }
  if (PAUSADO) {
    return (
      <Box sx={{ display: "flex", gap: 1 }}>
        <Chip
          icon={<Pending />}
          size="small"
          label={"Por confirmar"}
          sx={{ fontSize: { xs: 11, md: 13 } }}
        />
      </Box>
    );
  }

  return (
    <RenderApuestaAction data={data} charreada={charreada} torneo={torneo} />
  );
}

const RenderApuestaAction = ({ data, charreada, torneo }) => {
  const {
    snackMessage,
    tokenSesion,
    tokenDecoded,
    setErrors,
  } = React.useContext(MainContext);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [ganancia, setGanancia] = React.useState(0);
  const [createApuesta] = useMutation(CreateApuesta);

  const suerte = data.suerteData?.suerte;
  const isTeam = suerte.toLowerCase() === "equipo";

  const momioQuery = useQuery(GetCaculatedMomio, {
    variables: {
      input: {
        torneo: data.torneo,
        equipo: data.equipo,
        charreada: data.charreada,
        charro: data.charro,
        suerte: data.suerte,
        multiplicador: data.multiplicador,
        momioMin: data.momioMin,
        momioMax: data.momioMax,
      },
    },
    //fetchPolicy: "network-only",
  });

  React.useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_ENDPOINT);
    socket.on("updatedMomio", () => momioQuery?.refetch());
    return () => socket.disconnect();
  }, [momioQuery]);

  React.useEffect(() => {
    if (momioQuery?.error)
      setErrors({ ...momioQuery?.error, funcion: "getCalcCompetencia" });
  }, [momioQuery, setErrors]);

  if (momioQuery?.loading) {
    return (
      <Button
        disabled
        disableElevation
        variant="contained"
        className="fontAnton"
        startIcon={<CircularProgress size={18} color="inherit" />}
        sx={{ fontSize: { xs: 11, md: 14 } }}
      >
        Juega
      </Button>
    );
  }
  if (momioQuery?.error) {
    return (
      <Button
        disabled
        disableElevation
        variant="contained"
        className="fontAnton"
        startIcon={<InfoOutlined color="disabled" />}
        sx={{ fontSize: { xs: 11, md: 14 } }}
      >
        Juega
      </Button>
    );
  }

  const momio = momioQuery?.data?.getCalcCompetencia?.momio || 0;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setValue(0);
    setGanancia(0);
  };

  const handleSelectToros = (value) => {
    setValue(parseFloat(value || 0));
    setGanancia(value * parseFloat(momio) + value);
  };

  const crearApuestaBD = async () => {
    try {
      if (!value) {
        snackMessage({
          message: "Seleccione un monto",
          variant: "error",
        });
        return;
      }
      if (!tokenDecoded) {
        snackMessage({
          message: "No hay sesion",
          variant: "error",
        });
        return;
      }
      setLoading(true);
      const response = await createApuesta({
        variables: {
          input: {
            idCompetencia: data._id,
            torneo: torneo._id,
            charreada: charreada._id,
            equipo: data.equipo,
            suerte: data.suerte,
            charro: data.charro,
            usuario: tokenDecoded._id,
            apuesta: value,
            totalGanar: ganancia,
            momio,
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.createApuesta.message,
        variant: "success",
      });
      setLoading(false);
      handleClose();
    } catch (error) {
      console.log(error);
      setLoading(false);
      snackMessage({
        message: error.message ? error.message : "Error Desconocido",
        variant: "error",
      });
      setErrors({ ...error, funcion: "createApuesta" });
    }
  };

  const montos = [50, 100, 200, 300, 500, 1000];

  return (
    <React.Fragment>
      {!tokenDecoded ? (
        <DialogSesion
          momioQuery={momioQuery}
          suerte={data.suerteData?.suerte}
          momio={momio}
        />
      ) : (
        <Button
          disabled={momioQuery?.loading || momioQuery?.error}
          disableElevation
          variant="contained"
          className="fontAnton"
          onClick={handleClickOpen}
          startIcon={formatoMexico(momio)}
          sx={{ fontSize: { xs: 11, md: 14 } }}
        >
          Juega
        </Button>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle component="div">
          <Typography>{torneo.nombre}</Typography>
          <Typography variant="body1">{charreada.nombre}</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {montos.map((monto) => (
              <Grid key={monto} item xs={4} md={2}>
                <Button
                  fullWidth
                  variant={value === monto ? "contained" : "outlined"}
                  disableElevation
                  onClick={() => handleSelectToros(monto)}
                  startIcon={<FaCoins style={{ fontSize: 13 }} />}
                >
                  {monto}
                </Button>
              </Grid>
            ))}
          </Grid>
          <Container>
            <Box sx={{ mt: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 1,
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                <Typography>
                  <b>Suerte:</b>
                </Typography>
                <Typography>
                  <b>{data.suerteData.suerte}</b>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 1,
                }}
              >
                {isTeam ? (
                  <Typography>
                    <b>{data.equipoData.nombre}</b>
                  </Typography>
                ) : (
                  <Typography>
                    <b>{data.charroData.nombre}</b>
                  </Typography>
                )}
              </Box>
              <Divider />
              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                <Typography>Momio:</Typography>
                <Typography>
                  <b>{formatoMexico(momio)}</b>
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                <Typography>En juego:</Typography>
                <Typography
                  sx={{ gap: 0.5, display: "flex", alignItems: "center" }}
                >
                  <span>
                    <FaCoins style={{ fontSize: 13 }} />
                  </span>
                  <b>{formatoMexico(value)}</b>
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                <Typography>Posible recompensa:</Typography>
                <Typography
                  sx={{ gap: 0.5, display: "flex", alignItems: "center" }}
                >
                  <span>
                    <FaCoins style={{ fontSize: 13 }} />
                  </span>
                  <b>{formatoMexico(ganancia)}</b>
                </Typography>
              </Box>
            </Box>
          </Container>
        </DialogContent>
        <Box
          sx={{
            p: 1,
            gap: 1,
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
          }}
        >
          <Button
            fullWidth
            disableElevation
            color="inherit"
            variant="contained"
            onClick={handleClose}
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            onClick={crearApuestaBD}
            variant="contained"
            disableElevation
            fullWidth
            startIcon={
              loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <Done />
              )
            }
            disabled={loading}
          >
            Jugar
          </Button>
        </Box>
      </Dialog>
    </React.Fragment>
  );
};
