import { gql } from "@apollo/client";

export const GetApuestas = gql`
  query GetApuestas($filter: inputFilter, $limit: Int, $page: Int) {
    getApuestas(filter: $filter, limit: $limit, page: $page) {
      docs {
        _id
        torneo
        torneoData {
          _id
          nombre
        }
        equipo
        equipoData {
          _id
          nombre
        }
        suerte
        suerteData {
          _id
          suerte
        }
        charro
        charroData {
          _id
          nombre
        }
        charreada
        charreadaData {
          _id
          nombre
        }
        usuario
        usuarioData {
          _id
          nombre
        }
        nombre_usuario
        apuesta
        idCompetencia
        momio
        fecha
        status
        ticket
        full_description
        torneo_name
        suerte_name
        charro_name
        charreada_name
        equipo_name
      }
      page
      totalDocs
      totalPages
    }
  }
`;

export const GetCatsApuesta = gql`
  query GetCatalogos($filter: filterCatInput) {
    getCatalogos(filter: $filter) {
      torneos {
        _id
        nombre
      }
      charreadas {
        _id
        nombre
      }
      equipos {
        _id
        nombre
      }
      suertes {
        _id
        suerte
      }
      charros {
        _id
        nombre
      }
      statusApuesta {
        status
      }
    }
  }
`;
