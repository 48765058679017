import { withSnackbar } from "notistack";
import React, { createContext } from "react";
import jwtDecode from "jwt-decode";
import { useCookies } from "react-cookie";

export const MainContext = createContext();

const initialPaymentState = { title: "", value: 0, img: "", monto: 0, completed: false }

const MainCtxProvider = ({ children, enqueueSnackbar }) => {
  const [cookies, setCookie] = useCookies(["_ScSesion"]);
  const tokenSesion = cookies?._ScSesion;
  const decoded = tokenSesion ? jwtDecode(tokenSesion) : null;
  const [tokenDecoded, setTokenDecoded] = React.useState(decoded);
  const [theming, setTheming] = React.useState(localStorage.getItem("theming"));
  const [errors, setErrors] = React.useState(null);
  const [dataPayment, setDataPayment] = React.useState(initialPaymentState);

  const snackMessage = React.useCallback(
    ({
      message = "No Message",
      variant = "default",
      preventDuplicate = true,
      anchorOrigin = { horizontal: "center", vertical: "top" },
    }) => {
      enqueueSnackbar(message, {
        variant,
        preventDuplicate,
        anchorOrigin,
        style: {
          top: 35,
        },
      });
    },
    [enqueueSnackbar]
  );

  const handleCloseSesion = () => {
    setCookie("_ScSesion", "", { path: "/", expires: new Date(0) });
    setTokenDecoded(null);
  };

  return (
    <MainContext.Provider
      value={{
        snackMessage,
        tokenDecoded,
        tokenSesion,
        setCookie,
        handleCloseSesion,
        setTokenDecoded,
        theming,
        setTheming,
        errors,
        setErrors,
        dataPayment,
        setDataPayment,
        initialPaymentState
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

export default withSnackbar(MainCtxProvider);
