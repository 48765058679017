import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CircularProgress from "@mui/material/CircularProgress";
import { EventBusy } from "@mui/icons-material";
import { useMutation } from "@apollo/client";
import { MainContext } from "../../../../../../Context/MainCtx";
import { UpdateStateNPComp } from "../../graphql/mutations";
import { CompetenciaContext } from "../../Context/CompetenciaContext";
import { DialogContent, DialogContentText } from "@mui/material";

export default function SetNoSePresento({ handleCloseMenu }) {
  const [loading, setLoading] = React.useState(false);
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const {
    compQuery,
    itemsSelected,
    setItemsSelected,
    setIndeterminated,
  } = React.useContext(CompetenciaContext);
  const [updateStateNPComp] = useMutation(UpdateStateNPComp);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectNP = async () => {
    try {
      if (!itemsSelected.length) return;
      const idCompetencia = itemsSelected[0]._id;
      setLoading(true);
      const res = await updateStateNPComp({
        variables: {
          idCompetencia,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: res.data.updateStateNPComp.message,
        variant: "success",
      });
      setLoading(false);
      compQuery.refetch();
      setItemsSelected([]);
      setIndeterminated(false);
      handleClose();
      handleCloseMenu();
    } catch (err) {
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  const disabled = () => {
    if (!itemsSelected.length) return true;
    if (itemsSelected.length > 1) return true;
    return false;
  };

  const getJuego = () => {
    try {
      if (!itemsSelected.length) return "";
      const compt = itemsSelected[0];
      const suerte = compt.suerteData?.suerte;
      const isTeam = suerte.toLowerCase() === "equipo";
      const juego = isTeam ? compt.equipoData.nombre : compt.charroData.nombre;
      return `"${juego}" en ${compt.charreadaData.nombre} del torneo ${compt.torneoData.nombre}`;
    } catch (error) {
      console.log(error);
      return "";
    }
  };

  return (
    <>
      <ListItem disableGutters disablePadding>
        <ListItemButton onClick={handleClick} disabled={disabled()}>
          <ListItemIcon>
            <EventBusy fontSize="large" />
          </ListItemIcon>
          <ListItemText primary="No se presento" />
        </ListItemButton>
      </ListItem>
      <Dialog open={open} fullWidth maxWidth="xs">
        <DialogTitle>Seleccionar "No se presento"</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Se perderán todos los juegos que
            se jugaron con ${getJuego()}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ textTransform: "none" }}
            color="inherit"
            size="small"
            onClick={handleClose}
            variant="contained"
            fullWidth
            disableElevation
          >
            Cancelar
          </Button>
          <Button
            sx={{ textTransform: "none" }}
            color="info"
            size="small"
            onClick={handleSelectNP}
            variant="contained"
            fullWidth
            disableElevation
            startIcon={
              loading ? <CircularProgress color="inherit" size={20} /> : null
            }
          >
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
