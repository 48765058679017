import React from "react";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import ListItemButton from "@mui/material/ListItemButton";
import { useMutation } from "@apollo/client";
import { CircularProgress, List, ListItemText } from "@mui/material";
import { UpdateStatusTorneo } from "../graphql/mutations";
import { MainContext } from "../../../../../Context/MainCtx";
import { getColorStatusTorneo } from "../../../../../Config/reuserFunctions";
import { STATUS_TORNEO } from "../../../../../Config/constantes";

export default function ChipStatus({ torneo, refetch }) {
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(null);
  const open = Boolean(anchorEl);
  const [updateStatusTorneo] = useMutation(UpdateStatusTorneo);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateStastus = async (status) => {
    try {
      handleClose();
      setLoading(true);
      await updateStatusTorneo({
        variables: {
          idTorneo: torneo._id,
          status,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      refetch();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
      console.log(err.networkError?.result);
    }
  };

  return (
    <>
      <Chip
        id="custom-chip-status"
        aria-controls={open ? "status-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        component={"span"}
        label={torneo.status}
        size="small"
        icon={
          loading ? (
            <CircularProgress sx={{ color: "white!important" }} size={16} />
          ) : null
        }
        sx={{
          mr: 0.5,
          fontWeight: "600",
          bgcolor: getColorStatusTorneo(torneo.status),
          "&:hover": {
            bgcolor: getColorStatusTorneo(torneo.status),
          },
        }}
        onClick={handleClick}
      />
      <Menu
        id="status-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "custom-chip-status",
        }}
      >
        <List disablePadding dense>
          {Object.keys(STATUS_TORNEO).map((res, index) => (
            <ListItemButton
              key={index}
              onClick={() => updateStastus(STATUS_TORNEO[res])}
              selected={STATUS_TORNEO[res] === torneo.status}
            >
              <ListItemText>{STATUS_TORNEO[res]}</ListItemText>
            </ListItemButton>
          ))}
        </List>
      </Menu>
    </>
  );
}
