import { red } from "@mui/material/colors";

export const light = {
  palette: {
    mode: "light",
    primary: {
      main: red["A200"],
    },
    secondary: {
      main: red["A400"],
    },
    customPaper: "#f6f8fb",
    navbar: "#151929",//red["A200"],
    scharra1: "#D72022", 
    scharra2: "#5D9E33" 
  },
};
export const dark = {
  palette: {
    mode: "dark",
    primary: {
      main: red["A100"],
    },
    secondary: {
      main: "#48a2f0",
    },
    background: {
      default: "#151929",
      paper: "#181c2b",
    },
    navbar: "#151929",
    customPaper: "#1a203b",
    scharra1: "#D72022", 
    scharra2: "#5D9E33" 
  },
};
