import React, { createContext } from "react";
import { MainContext } from "../../../../../Context/MainCtx";
import { useDebounce } from "use-debounce";
import { useApolloClient, useQuery } from "@apollo/client";
import { GetCatsMov, GetMovimientosReport } from "../graphql/queries";
import moment from "moment-timezone";

export const MovimientoContext = createContext();

const initialFilter = {
  fechaInicio: moment().format(),
  fechaFin: moment().format(),
  movimiento: "",
};

const initialCats = {
  movimientos: "",
  status: "",
  torneo: "",
};

const inititalState = {
  loading: false,
  error: null,
  data: null,
};

const MovimeintoCtxProvider = ({ children }) => {
  const { tokenSesion, snackMessage } = React.useContext(MainContext);
  const [filter, setFilter] = React.useState(initialFilter);
  const [filterCats, setFilterCats] = React.useState(initialCats);
  const [query, setQuery] = React.useState(inititalState);
  const [FILTER] = useDebounce(filter, 500);
  const [FILTERCATS] = useDebounce(filterCats, 500);
  const [loaded, setLoaded] = React.useState(false);
  const [pageConfig, setPageConfig] = React.useState({ page: 0, limit: 25 });
  const { page, limit } = pageConfig;

  const client = useApolloClient();

  const getMovimientos = async (pagina = page, limite = limit) => {
    try {
      if (!filter.fechaInicio) {
        snackMessage({
          message: "Fecha inicio es obligatorio",
          variant: "warning",
        });
        return;
      }
      if (!filter.fechaFin) {
        snackMessage({
          message: "Fecha fin es obligatorio",
          variant: "warning",
        });
        return;
      }
      if (!filter.movimiento) {
        snackMessage({
          message: "Tipo movimiento es obligatorio",
          variant: "warning",
        });
        return;
      }
      setQuery((prev) => ({ ...prev, loading: true }));
      const response = await client.query({
        query: GetMovimientosReport,
        variables: {
          filter: FILTER,
          page: pagina,
          limit: limite,
        },
        fetchPolicy: "network-only",
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      setLoaded(true);
      if (!response.data?.getMovimientosReport?.docs?.length) {
        snackMessage({
          message: "No se encontraron resultados",
          variant: "info",
        });
      }
      setQuery((prev) => ({
        ...prev,
        loading: false,
        data: response.data?.getMovimientosReport || null,
      }));
    } catch (error) {
      setQuery((prev) => ({ ...prev, loading: false, error }));
      console.log(error);
      snackMessage({
        message: error.message ? error.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  const catsQuery = useQuery(GetCatsMov, {
    fetchPolicy: "network-only",
    variables: {
      filter: FILTERCATS,
    },
  });

  return (
    <MovimientoContext.Provider
      value={{
        filter,
        setFilter,
        initialFilter,
        initialCats,
        catsQuery,
        filterCats,
        setFilterCats,
        query,
        setQuery,
        getMovimientos,
        FILTER,
        loaded,
        setLoaded,
        pageConfig,
        setPageConfig,
      }}
    >
      {children}
    </MovimientoContext.Provider>
  );
};

export default MovimeintoCtxProvider;
