import { gql } from "@apollo/client";

export const GetCompetencias = gql`
  query getCompetencias($filter: inputCompFilter, $limit: Int, $page: Int) {
    getCompetencias(filter: $filter, limit: $limit, page: $page) {
      docs {
        _id
        torneo
        torneoData {
          nombre
        }
        charreada
        charreadaData {
          nombre
        }
        equipo
        equipoData {
          nombre
        }
        suerte
        suerteData {
          suerte
        }
        charro
        charroData {
          nombre
        }
        multiplicador
        momioMin
        momioMax
        status
        ganador
        createdAt
        fechaCharreada
        noSePresento
        torneo_name
      }
      totalDocs
      page
      totalPages
    }
  }
`;

export const GetCatsComp = gql`
  query GetCatalogos($filter: filterCatInput) {
    getCatalogos(filter: $filter) {
      charreadas {
        _id
        nombre
      }
      torneos {
        _id
        nombre
        lugar
        lienzoCharro
        fechaInicio
        fechaFin
        status
        abrev
        tipo
        createdAt
      }
      equipos {
        _id
        nombre
        estado {
          _id
          estado
          pais
        }
        createdAt
      }
      suertes {
        _id
        suerte
      }
      charros {
        _id
        nombre
        equipo
        equipoData {
          _id
          nombre
          createdAt
        }
        foto
        keyFoto
      }
      status {
        status
      }
      statusApuesta {
        status
      }
      statusComp {
        status
      }
    }
  }
`;
export const GetCaculatedFields = gql`
  query getCalcCompetencia($input: InputCalc) {
    getCalcCompetencia(input: $input) {
      promedio
      mayor
      menor
      aritmetica
      HECTOR
      media
      probabilidad
      constante
      factor
      rendimiento
      cuota
      valorPunto
      ponderador
      ponderado
      apuestas
      totalApuestas
      bolsa
      bolsaMenosApuesta
      porcentajeCasa
      bolsaMenosApuestaMenosPorcentajeCasa
      momio
    }
  }
`;
export const GetBetsByCompetencia = gql`
  query getBetsByCompetencia(
    $filter: String
    $competencia: ID
    $limit: Int
    $page: Int
  ) {
    getBetsByCompetencia(
      filter: $filter
      competencia: $competencia
      limit: $limit
      page: $page
    ) {
      docs {
        _id
        usuario
        usuarioData {
          _id
          nombre
        }
        nombre_usuario
        apuesta
        momio
        totalGanar
        fecha
        status
        ganada
      }
      page
      totalDocs
      totalPages
    }
  }
`;
