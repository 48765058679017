import React from "react";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import ListItemButton from "@mui/material/ListItemButton";
import { useMutation } from "@apollo/client";
import { CircularProgress, List, ListItemText } from "@mui/material";
import { UpdateTipoTorneo } from "../graphql/mutations";
import { MainContext } from "../../../../../Context/MainCtx";
import { getColorTipoEvento } from "../../../../../Config/reuserFunctions";

export default function ChipTipoEvento({ torneo, refetch }) {
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(null);
  const open = Boolean(anchorEl);
  const [updateTipoTorneo] = useMutation(UpdateTipoTorneo);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateTipo = async (tipo) => {
    try {
      handleClose();
      setLoading(true);
      await updateTipoTorneo({
        variables: {
          idTorneo: torneo._id,
          tipo,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      refetch();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
      console.log(err.networkError?.result);
    }
  };

  return (
    <>
      <Chip
        id="custom-chip-tipo"
        aria-controls={open ? "tipo-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        component={"span"}
        label={torneo.tipo}
        size="small"
        icon={
          loading ? (
            <CircularProgress sx={{ color: "white!important" }} size={16} />
          ) : null
        }
        sx={{
          mr: 0.5,
          fontWeight: "600",
          bgcolor: getColorTipoEvento(torneo.tipo),
          "&:hover": {
            bgcolor: getColorTipoEvento(torneo.tipo, "hover"),
          },
        }}
        onClick={handleClick}
      />
      <Menu
        id="tipo-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "custom-chip-tipo",
        }}
      >
        <List disablePadding dense>
          <ListItemButton
            onClick={() => updateTipo("ORO")}
            selected={"ORO" === torneo.status}
          >
            <ListItemText>ORO</ListItemText>
          </ListItemButton>
          <ListItemButton
            onClick={() => updateTipo("PLATA")}
            selected={"PLATA" === torneo.status}
          >
            <ListItemText>PLATA</ListItemText>
          </ListItemButton>
          <ListItemButton
            onClick={() => updateTipo("BRONCE")}
            selected={"BRONCE" === torneo.status}
          >
            <ListItemText>BRONCE</ListItemText>
          </ListItemButton>
        </List>
      </Menu>
    </>
  );
}
