import React from "react";
import { useApolloClient } from "@apollo/client";
import ExcelExportButton from "../../../../Components/ExcelExportButton";
import { MovimientoContext } from "../Context/MovimientoContext";
import { GetMovimientosUser } from "../graphql/queries";
import { MainContext } from "../../../../Context/MainCtx";
import { formatoFechaHora } from "../../../../Config/reuserFunctions";

const formatTable = (data = []) => {
  const dataFormat = [];
  data.forEach((res) => {
    const { _id, usuarioData, fecha, ...newData } = res;
    dataFormat.push({
      ...newData,
      usuario: usuarioData?.nombre || "",
      fecha: formatoFechaHora(fecha)
    });
  });
  return dataFormat;
};

export default function ExcelExport() {
  const { movQuery, filter } = React.useContext(MovimientoContext);
  const { tokenSesion, snackMessage, tokenDecoded } = React.useContext(MainContext);
  const excelData = formatTable(movQuery.data?.getMovimientosUser?.docs);
  const [loading, setLoading] = React.useState(false);

  const client = useApolloClient();

  /* Queries */
  const getMovimientosUser = async () => {
    try {
      setLoading(true);
      const response = await client.query({
        query: GetMovimientosUser,
        variables: {
          idUsuario: tokenDecoded._id,
          filter,
        },
        fetchPolicy: "network-only",
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      setLoading(false);
      //console.log(response)
      if (response.data) {
        return formatTable(response.data.getMovimientosUser.docs);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      snackMessage({
        message: error.message ? error.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      <ExcelExportButton
        data={excelData}
        name="Suerte charra - movimientos"
        getQueryData={getMovimientosUser}
        loading={loading}
        size="medium"
      />
    </div>
  );
}
