import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import {
  formatoFechaHora,
  formatoMexico,
  getColorStatusApuesta,
} from "../../../../Config/reuserFunctions";
import { ApuestasContext } from "../Context/ApuestasContext";
import { STATUS_APUESTA } from "../../../../Config/constantes";

export default function ApuestasTableBody({ data, handleSelect }) {
  const { itemsSelected } = React.useContext(ApuestasContext);
  
  return (
    <TableBody>
      {data.map((res) => {
        const checked = itemsSelected.find((item) => item._id === res._id);
        return (
          <TableRow
            role="checkbox"
            tabIndex={-1}
            key={res._id}
            sx={{
              height: "30px",
              "& .cell": {
                fontSize: 12,
                py: 0,
              },
            }}
          >
            <TableCell
              padding="checkbox"
              sx={{
                position: "sticky",
                left: 0,
                zIndex: 2,
                bgcolor: (theme) => theme.palette.background.paper,
                borderLeft: `5px solid ${getColorStatusApuesta(res.status)}`,
              }}
            >
              <Checkbox
                onChange={(e) => handleSelect(e.target.checked, res)}
                onClick={(e) => e.stopPropagation()}
                checked={checked ? true : false}
                disabled={res.status === STATUS_APUESTA.cancelada}
              />
            </TableCell>
            <TableCell className="cell">{res.torneoData?.nombre || res?.torneo_name || ""}</TableCell>
            <TableCell className="cell">{res.charreadaData?.nombre || res?.charreada_name || ""}</TableCell>
            <TableCell className="cell">{res.equipoData?.nombre || res?.equipo_name || ""}</TableCell>
            <TableCell className="cell">{res.suerteData?.suerte || res?.suerte_name || ""}</TableCell>
            <TableCell className="cell">{res.charroData?.nombre || res?.charro_name || ""}</TableCell>
            <TableCell className="cell">{res.usuarioData?.nombre || res.nombre_usuario}</TableCell>
            <TableCell className="cell">
              ${formatoMexico(res.apuesta)}
            </TableCell>
            <TableCell className="cell">{formatoMexico(res.momio)}</TableCell>
            <TableCell className="cell">
              {formatoFechaHora(res.fecha)}
            </TableCell>
            <TableCell className="cell">{res.status}</TableCell>
            <TableCell className="cell">{res.ticket}</TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}
